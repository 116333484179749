.theme-btn {
	color: $white;
	display: inline-block;
	font-weight: 500;
	padding: 15px 35px;
	border: 0;
	text-transform: uppercase;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	font-size: 18px;
	background: linear-gradient(90deg, #ED6B37 11.32%, #EC180C 95.28%);
	font-family: $heading-font;
	border-radius: 0px;


	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity 0.5s ease-out;
		background: linear-gradient(90deg, #EC180C 11.32%, #ED6B37 95.28%);
		opacity: 0;
		z-index: -1;
		border-radius: 5px;
	}

	&:hover,
	&:focus,
	&:active {
		color: $white;
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(991px) {
		font-size: 18px;
		font-size: calc-rem-value(18);
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 14px;
	}
}

.view-cart-btn {
	@extend .theme-btn;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	padding: 10px 45px;



	&:after {
		display: none;
	}

	&:hover {
		background-color: darken($theme-primary-color, 8%);
	}

	&.s1 {
		background: #ddd;

		&:hover {
			background-color: darken(#ddd, 8%);
		}
	}

}