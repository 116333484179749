/*======================================
6. Home-style-4
 =======================================*/

/* 7.1 wpo-about-section-s4 */

.wpo-about-section-s4 {

    @media(max-width:991px){
        padding-top: 70px;
    }

    .wpo-about-wrap {
        padding: 40px;
        border: 1px solid $theme-primary-color;

        @media(max-width:991px){
            padding: 10px;
        }

        .wpo-about-img {
            padding: 0;
            border: 0;

            .wpo-about-img-text{
                display: none;
            }
        }
        
        .wpo-about-text{
            @media(max-width:991px){
                margin-top: 30px;
            }
        }
    }
}


/* 7.2 wpo-campaign-area-s4 */


.wpo-campaign-area-s4 {
    padding-top: 0;

    &:before,
    &:after {
        display: none;
    }

    .wpo-campaign-wrap {
        ul {
            justify-content: space-between;
            margin-bottom: 40px;
            border: 0;

            @media(max-width:575px){
                justify-content: center;
            }

            li {
                flex-basis: 20%;

                @media(max-width:575px){
                    flex-basis: 30%;
                }

                @media(max-width:450px){
                    flex-basis: 50%;
                }

                a {
                    padding: 20px 30px;
                    text-align: center;
                    font-weight: 450;
                    font-size: 25px;
                    line-height: 32px;
                    font-family: $heading-font;
                    color: $dark-gray;
                    border: 1px solid $border-color;
                    border-radius: 0;
                    margin: -1px;
                    cursor: pointer;

                    @media(max-width:1199px){
                        padding: 12px;
                        font-size: 18px;
                    }
                    @media(max-width:767px){
                        padding: 10px;
                        font-size: 14px;
                    }

                    &.active {
                        background: $theme-primary-color-gradient;
                        color: $white;
                    }
                }
            }
        }
    }
}

/* 7.3 wpo-fun-fact-section-s4 */

.wpo-fun-fact-section-s4{
    padding-bottom: 120px;
    background: none;

    @media(max-width:991px){
       padding-bottom: 90px;
    }
    @media(max-width:767px){
       padding-bottom: 80px;
    }

    .wpo-fun-fact-wrap {
        .wpo-fun-fact-grids {
            padding: 0;
            .grid {
                &:before{
                    background-color: #CBD0EB!important;
                }
                h3{
                    color: $dark-gray;
                }

                p{
                    color: $text-color;
                }
            }
        }
    } 
}