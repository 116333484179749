/*======================================
6. Home-style-3
 =======================================*/

/* 6.1 wpo-features-s2 */

.wpo-features-s2 {
    .features-wrap {
        .feature-item {
            padding: 44px 50px;
            background: $white;
            box-shadow: 0px 2px 10px rgba(0, 18, 52, 0.15);
            border-radius: 20px;
            text-align: center;

            @media(max-width:1199px) {
                padding: 44px 30px;
            }

            @media(max-width:991px) {
                margin-bottom: 30px;
            }

            .icon {
                width: 130px;
                height: 130px;
                line-height: 130px;
                border: 1px solid $theme-primary-color;
                margin: auto;
                border-radius: 50%;
                margin-bottom: 30px;
                position: relative;

                .fi {
                    &:before {
                        font-size: 60px;
                        background: $theme-primary-color-gradient;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }

            .feature-text {

                h2 {
                    font-weight: 450;
                    font-size: 30px;
                    line-height: 38px;
                    text-align: center;

                    a {
                        color: $dark-gray;

                        &:hover {
                            color: $theme-primary-color;
                        }
                    }
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    .features-dot {
        width: 100%;
        height: 100%;
        left: 56%;
        top: 8%;
    }

    .icon i.fi {
        position: absolute;
        left: 32px;
        top: 0;
    }

    .dots {
        width: 15px;
        height: 15px;
        background: $theme-primary-color-gradient;
        border-radius: 50%;
        left: 25px;
        top: 0;
        position: relative;

    }

    .features-content h2 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    .features-dot:nth-child(1) {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-animation: features-dot_1 2s infinite linear;
        -moz-animation: features-dot_1 2s infinite linear;
        -o-animation: features-dot_1 2s infinite linear;
        animation: features-dot_1 2s infinite linear;
        -webkit-animation-play-state: paused;
        -moz-animation-play-state: paused;
        -o-animation-play-state: paused;
        animation-play-state: paused;
    }

    .feature-item .features-wrapper:hover .features-dot:nth-child(1) {
        -webkit-animation-play-state: running;
        -moz-animation-play-state: running;
        -o-animation-play-state: running;
        animation-play-state: running;
    }


    @-webkit-keyframes features-dot_1 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-moz-keyframes features-dot_1 {
        0% {
            -moz-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -moz-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-o-keyframes features-dot_1 {
        0% {
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes features-dot_1 {
        0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes features-dot_2 {
        0% {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        100% {
            -webkit-transform: rotate(540deg);
            transform: rotate(540deg);
        }
    }

    @-moz-keyframes features-dot_2 {
        0% {
            -moz-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        100% {
            -moz-transform: rotate(540deg);
            transform: rotate(540deg);
        }
    }

    @-o-keyframes features-dot_2 {
        0% {
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        100% {
            -o-transform: rotate(540deg);
            transform: rotate(540deg);
        }
    }

    @keyframes features-dot_2 {
        0% {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        100% {
            -webkit-transform: rotate(540deg);
            -moz-transform: rotate(540deg);
            -o-transform: rotate(540deg);
            transform: rotate(540deg);
        }
    }
}


/* 6.2 wpo-about-section-s3 */

.wpo-about-section-s3 {
    @media(max-width:991px) {
        padding-top: 70px;
    }

    @media(max-width:575px) {
        padding-top: 50px;
    }

    .wpo-about-wrap {
        .wpo-about-img {
            padding: 0;
            border: 0;
            margin-left: -80px;

            @media(max-width:991px) {
                margin-left: 0;
            }

            .wpo-about-img-text{
                display: none;
            }
        }

        .wpo-about-text {
            @media(max-width:991px) {
                margin-top: 30px;
            }
        }
    }
}


/* 6.3 wpo-fun-fact-section-s3 */

.funfact-wrap-sec{
    .funfact-video{
        display: none;
    }
}

.wpo-fun-fact-section-s3 {
    padding-bottom: 0;
    background: linear-gradient(90deg, #ED6B37 11.32%, #EC180C 95.28%);

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: url(../../images/particale.png) no-repeat center center;
        background-size: cover;
    }

    .wpo-fun-fact-wrap {
        .wpo-fun-fact-grids {
            padding: 80px 0 70px;

            @media(max-width:1199px){
                padding: 50px 0 40px;
            }
            .grid+.grid:before {
                background: $white;
            }
        } 
    }
}

/* 6.4 wpo-campaign-area-s3  */

.wpo-campaign-area-s3 {
     margin: 0;
    .shape-left,
    .shape-right {
        display: none;
    }
}