/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
    background: linear-gradient(286.02deg, #080A14 38.02%, #10121E 61.92%, #282A34 74.32%, #404354 90.71%, #343643 99.65%);
    background-size: cover;
    position: relative;
    font-size: 16px;
    overflow: hidden;
    z-index: 1;

    &:before {
        position: absolute;
        right: 0;
        top: 0;
        width: 960px;
        height: 100%;
        background: #23242F;
        content: "";
        clip-path: polygon(57% 0, 100% 0, 100% 100%, 0% 100%);
        z-index: -1;
    }


    ul {
        list-style: none;
    }

    p {
        color: rgba(255, 255, 255, 0.6);
    }

    li {
        color: rgba(255, 255, 255, 0.6);
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        padding: 100px 0;
        position: relative;
        overflow: hidden;

        .navbar-brand {
            padding-top: 0;
            color: $white;
            justify-content: flex-start;
            position: relative;
            top: -10px;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 80px 0 0;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 20px;
    }

    .widget-title {
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 95%;
            color: $white;
            margin: 0;
            position: relative;
            font-family: $base-font;
        }
    }

    .about-widget {
        max-width: 245px;

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 25px;
        }

        .info {
            li {
                margin-bottom: 10px;
            }
        }

        .social ul {
            display: flex;
            margin-top: 20px;

            li {
                &+li {
                    margin-left: 10px;
                }

                a {
                    color: $white;
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    border: 1px solid rgba(255, 255, 255, 0.28);
                    display: block;
                    text-align: center;
                    font-size: 16px;

                    &:hover {
                        background: $theme-primary-color;
                    }

                    .fi {
                        &:before {
                            color: rgba(255, 255, 255, 0.72);
                            ;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .wpo-service-link-widget {
        padding-left: 70px;
    }

    .link-widget {
        overflow: hidden;
        padding-left: 15px;

        @media(max-width:1200px) {
            padding: 0;
        }

        &.s2 {
            padding-left: 15px;

            @media(max-width:1200px) {
                padding: 0;
            }
        }


        ul {
            li {
                position: relative;
                z-index: 1;
                padding: 8px 0;
                padding-left: 20px;

                &:before {
                    position: absolute;
                    left: 0px;
                    top: 16px;
                    width: 6px;
                    height: 6px;
                    z-index: -1;
                    background: #D9D9D9;
                    content: "";
                    border-radius: 50%;
                }

                a {
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 16px;

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }
        }
    }

    /*** newsletter-widget ***/
    .newsletter-widget {
        p {
            font-size: 16px;
        }

        form {
            margin-top: 25px;
            position: relative;

            input {
                background-color: transparent;
                height: 50px;
                color: $white;
                padding: 6px 15px;
                border: 1px solid rgba(255, 255, 255, 0.22);
                box-shadow: none;
                border-radius: 0;

                &::-webkit-input-placeholder {
                    color: $white;
                }

                &:-ms-input-placeholder {
                    color: $white;
                }

                &::placeholder {
                    color: $white;
                }
            }

            .submit {
                margin-top: 20px;

                button {
                    background: $theme-primary-color-gradient;
                    border: 0;
                    outline: 0;
                    color: $white;
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 95%;
                    text-transform: uppercase;
                }
            }
        }
    }

    .wpo-lower-footer {
        text-align: left;
        padding: 25px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.15);

        @media(max-width:991px) {
            text-align: center;
        }

        ul {
            li {
                color: rgba(188, 192, 197, 0.97);

                a {
                    color: $white
                }
            }
        }

        .link {
            ul {
                display: flex;
                justify-content: flex-end;

                @media(max-width:991px) {
                    justify-content: center;
                    margin-top: 10px;
                    flex-wrap: wrap;
                }

                li {
                    a {
                        color: rgba(188, 192, 197, 0.97);
                        ;

                        &:hover {
                            color: $theme-primary-color;
                        }
                    }

                    &+li {
                        padding-left: 20px;
                        position: relative;

                        @media(max-width:375px) {
                            padding-left: 5px;
                            margin-left: 5px;
                        }

                    }
                }
            }

        }
    }
}